
import * as echarts from 'echarts/core';

import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';

import {
    PieChart
} from 'echarts/charts';

import {
    CanvasRenderer
} from 'echarts/renderers'

echarts.use([TitleComponent, TooltipComponent, LegendComponent, PieChart, CanvasRenderer]);

var specChartDom = document.getElementById('specChar');
var specChart = echarts.init(specChartDom);
var specOption;

var cityChartDom = document.getElementById('cityChar');
var cityChart = echarts.init(cityChartDom);
var cityOption;

specOption = {
    title: {
        text: 'Distribution by specialty',
        subtext: '',
        left: 'center'
    },
    tooltip: {
        trigger: 'item'
    },
    legend: {
        orient: 'horizontal',
        left: 'bottom',
    },
    series: [
        {
            name: 'Specialists',
            type: 'pie',
            radius: '50%',
            data: specChartData,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

cityOption = {
    title: {
        text: 'Distribution by location',
        subtext: '',
        left: 'center'
    },
    tooltip: {
        trigger: 'item'
    },
    legend: {
        orient: 'horizontal',
        left: 'bottom',
    },
    series: [
        {
            name: 'City',
            type: 'pie',
            radius: '50%',
            data: cityChartData,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

specOption && specChart.setOption(specOption);
cityOption && cityChart.setOption(cityOption);
